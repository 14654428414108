.layoutflow {
	flex-grow: 1;
	position: relative;
	height: 100%;
	background-color: white;
	border: 1px solid black;
	border-radius: 8px;
}

.layoutflow .controls {
	position: absolute;
	right: 10px;
	top: 10px;
	z-index: 10;
	font-size: 12px;
}

.layoutflow .controls button:first-child {
	margin-right: 10px;
}
