.react-flow{
    background: transparent;
    width: 100%;
    
}
.react-flow .react-flow__node{
    width: 5rem;
    height: 2rem;
    border-radius: 2rem;
    background-color: rgb(128, 128, 128,.2);
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-style: none;
}
/* 
@media only screen and (max-width: 1000px) {
    .main{
        display: flex;
        flex-direction: column;
    }
} */
